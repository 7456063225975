<template>
  <main>
    <!-- Modal datos registros incompletos -->
    <div class="modal fade" id="modalUsuario" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle" data-bs-backdrop="status">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTitle">Datos de Usuario</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p style="text-transform: capitalize">
              Fecha de registro:
              {{ moment(viewDataArray.createdAt).format("DD/MM/YYYY HH:mm") }}
            </p>
            <!-- <p style="text-transform: capitalize;"> Tipo de Usuario:
              {{ viewDataArray}}</p> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteUserIncomplete()">Borrar Usuario</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Vista Principal -->
    <div class="card-title mt-5">
      <h3 class="card-label text-dark">Registros Incompletos</h3>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-header bg-primary pb-3 pt-3">
        <div class="form-group row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6"></div>
            </div>
          </div>
        </div>
      </div>
      <table class="table align-middle table-hover content">
        <thead>
          <tr align="center">
            <th>Correo</th>
            <th>Fecha Registro</th>
            <th>Estatus</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody v-for="information in dataPage" v-bind:key="information">
          <tr>
            <td align="center" style="width: 12%">{{ information.email }}</td>
            <td align="center">{{ moment(information.createdAt).format("DD/MM/YYYY") }}</td>
            <td align="center" style="display: flex; justify-content: center; align-content: center">
              <p
                class="badge"
                :class="{
                  'bg-danger': !information.register_complete,
                  'bg-success': information.register_complete,
                }"
              >
                {{ information.register_complete ? "Aceptado" : "Incompleto" }}
              </p>
            </td>
            <td align="center">
              <button type="button" class="btn btn-icon btn-success btn-hover-primary btn-sm mx-3" @click="viewData(information)" title="Ver usuario">
                <box-icon name="show" type="solid" color="white"></box-icon>
              </button>
            </td>
          </tr>
          <div class="modal fade" id="miModal2" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle" data-bs-backdrop="status"></div>
        </tbody>
      </table>
      <!-- Paginador -->
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li v-if="this.actual > 1" class="page-item" v-on:click="getPreviousPage()">
            <a class="page-link" href="#">Anterior</a>
          </li>
          <li v-for="page in totalPages()" v-bind:key="page" v-on:click="getDataPages(page)" v-bind:class="isActive(page)" class="page-item">
            <a v-if="page < this.actual + 7 && page > this.actual - 7" class="page-link" href="#">{{ page }}</a>
          </li>
          <li class="page-item" v-on:click="getNextPage()">
            <a class="page-link" href="#">Siguiente</a>
          </li>
        </ul>
      </nav>
    </div>
  </main>
</template>
<script>
import { AdminService } from "@/services";
import moment from "moment";
moment.locale("es");

export default {
  data: () => ({
    elementPage: 20,
    dataPage: [],
    viewDataArray: {
      createdAt: new Date(),
    },
    actual: 1,
    search: "",
    id: null,
    status: null,
    listUsuarios: [],
    modalUsuario: null,
    moment: moment,
  }),
  mounted() {
    this.usuariosIncompletos();
    setTimeout(() => {
      this.initialModal();
    }, 1000);
  },
  methods: {
    initialModal() {
      this.modalUsuario = new window.bootstrap.Modal(document.getElementById("modalUsuario"), {
        keyboard: false,
      });
    },
    async usuariosIncompletos() {
      let resp = await AdminService.getUsuariosNoRegistrados({});
      let reverseData = resp.data.data;
      this.listUsuarios = reverseData;
      this.listUsuarios.length > 0 ? this.getDataPages(1) : console.log("Esperando...");
    },
    viewData(usuario) {
      console.log(this.viewDataArray);
      this.viewDataArray = usuario;
      this.id = usuario._id;
      this.modalUsuario.show();
    },
    deleteUserIncomplete() {
      AdminService.deleteUser({ id: this.id })
        .then((res) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.usuariosIncompletos();
        })
        .catch((e) => {
          this.$swal({
            position: "top-end",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    totalPages() {
      return Math.ceil(this.listUsuarios.length / this.elementPage);
    },
    getDataPages(numberPage) {
      this.actual = numberPage;
      this.dataPage = [];
      let start = numberPage * this.elementPage - this.elementPage;
      let end = numberPage * this.elementPage;
      this.dataPage = this.listUsuarios.slice(start, end);
    },
    getPreviousPage() {
      if (this.actual > 1) {
        this.actual--;
      }
      this.getDataPages(this.actual);
    },
    getNextPage() {
      if (this.actual < this.totalPages()) {
        this.actual++;
      }
      this.getDataPages(this.actual);
    },
    isActive(numberPage) {
      return numberPage === this.actual ? "active" : "inactive";
    },
  },
};
</script>
